import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Talk from 'talkjs';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TalkService {
  private APP_ID = environment.talk.appId;
  currentUser: Talk.User;
  currentSession: Talk.Session;
  headers: HttpHeaders;
  authHeader = environment.wibi.basicAuth
  url = environment.wibi.url;

  customerSupportData = new BehaviorSubject([])

  constructor(
    private auth: AuthService,
    private http: HttpClient,
  ) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', this.authHeader);
    this.fetchRecommendation().subscribe(rec => {
      console.log('.>', rec)
      rec = rec.map((r: any) => {
        return {
          id: r.talk_id,
          photoUrl: r.image,
          availabilityText: r.availability_ext,
          ...r,
        };
      });
      console.log('.???', rec)
      rec.sort((a: any, b: any) => {
        if (a.talk_id < b.talk_id) {
          return -1;
        }

        if (a.talk_id > b.talk_id) {
          return 1;
        }
        return 0
      });
      this.customerSupportData.next(rec);
    });
  }

  async initCurrentSession(recs: any[]) {
    await Talk.ready;
    const username = this.auth.getUsername();
    const cs = recs.find(data => data.id === username);
    this.currentUser = new Talk.User(cs);
    console.log('>>>> CURRENT USER', this.currentUser);
    const session = new Talk.Session({
         appId: this.APP_ID,
         me: this.currentUser
    });
    this.currentSession = session;
    return session;
  }

  fetchRecommendation() {
    const url = `${this.url}/v1/nilaiku/talk/cs/recommendations/`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  logout() {
    this.currentSession.destroy();
    this.auth.logout();
  }

}
